.swatch-opt {
  width: 100%;
  .swatch-option.text {
    min-width: 60px;
    padding: 5px 5px;
    margin-right: 5px;
    color: #333;
  }
  .swatch-attribute-selected-option {
    display: none;
  }
  .swatch-option {
    align-items: center;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 1.2rem;
    float: left;
    overflow: hidden;
    position: relative;
    &.disabled {
      opacity: 0.1;
      @include defaultTrans;
    }
    &.selected {
      border: 4px solid;
    }
  }
  input.swatch-input.super-attribute-select {
    display: none;
  }
  .swatch-attribute {
    &.size .text{
      min-width: inherit !important;
      @include fontDefault;
      font-size: 1.8rem;
    }
  }
}
