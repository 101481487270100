.home {
  .page-main {
    padding-top: 0;
  }
}
header, section, footer, aside, nav, main, article, figure {
  display: block;
}
/*Spide*/
.elementor-widget .widget-content .splide .splide__slide.is-hidden {
  visibility: hidden;
  width: 0;
  height: 0;
}
.elementor-widget .widget-content .splide {
  visibility: visible !important;
}
ul.faq{
  border-bottom: 1px solid $colorPrimary;
  padding-bottom: 2rem;
  li{
    list-style: disc;
    list-style-position: inside;
    margin-bottom: 1rem;
    a:hover{
      color: $colorPrimary;
    }
  }
}
.cms-faq{
  .pages.wrapper{
    > div{
      border-bottom: 1px solid $colorPrimary;
      padding-bottom: 2rem;
    }
  }
}
ul.splide__pagination{
  bottom: 3rem;
  justify-content: center;
  li {
    button {
      border: 0rem solid white;

      opacity: 0.6;
      color: $colorPrimary;
      width: .8rem;
      background:  $colorPrimary;
      height: .8rem;
      -webkit-border-radius: 5rem;
      -moz-border-radius: 5rem;
      border-radius: 5rem;
      &:before {
        color: transparent;
      }
      &.is-active {
        opacity: 1;
        background:  $colorPrimary;
        transform: none;
        &:hover {

        }
      }
    }
  }
}
button.splide__arrow.splide__arrow{
  width: 4rem;
  height: auto;
  background-color: transparent;
  top: 40%;
  display: flex;
  align-items: center;
  font-size: 3rem;
  &:before{
    @include fontIcon;
    height: auto;
    width: auto;
    display: inline-block;
    color: #AFAFAF;
  }
  &--prev {
    left: -4rem;


    &:before{
      content: "\f053";
    }
  }
  &--next{
    right: -4rem;
    left: inherit;
    &:before{
      content: "\f054";
    }

  }
}

button.splide__arrow.splide__arrow--next, button.splide__arrow.splide__arrow--prev {
  svg {
    display: none;
  }
}

button.slick-prev.slick-arrow {
  width: 4rem;
  height: 4rem;
  right: 6rem;
  left: inherit;
  bottom: -7rem;
  top: inherit;
  transform: rotate(180deg);
  background-color: transparent;
  &:before {
    color: transparent;
  }
}
button.slick-next.slick-arrow{
  width: 4rem;
  height: 4rem;
  background-color: transparent;
  right: 0rem;
  left: inherit;
  bottom: -9rem;
  top: inherit;
  &:before {
    color: transparent;
  }
}
/*1 Col Layout*/

.cms-impressum.page-layout-1column .page-main, body.cms-datenschutz main, .cms-agbs main {
  margin: 0 auto;
}

/*2 Col Layout*/
.page-layout {
  &-1column{
    .products, .form.confirmation {
      @include contentMedium;
      .list {
        .product {
          &-item {
            width: calc(25% - 8px);
            margin-right: 10px;
            &:nth-of-type(3n) {
              margin-right: 10px;
            }
            &:nth-of-type(4n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  &-2columns-left {

    .column {
      width: calc(100% - 280px);
      width: 75%;
      margin: 0;
      @media only screen and (max-width: #{$mediumBreakpoint}) {
        width: 100%;
      }
    }

    .sidebar-main {
      background: transparent;
      padding: 0;

      .category {
        &-item {
          &.active {

            > a {
              text-decoration: underline;
            }


            i {
              &.fa-plus {
                display: none;
              }

              &.fa-minus {
                display: inline-block;
              }
            }
          }
        }

        &-content {
          position: relative;

          span {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 1.4rem;
            color: $colorBlack;
            width: 4rem;
            cursor: pointer;
            text-align: right;

            i {
              display: none;

              &.fa-plus {
                display: inline-block;
              }
            }

            &.active {
              i {
                &.fa-plus {
                  display: none;
                }

                &.fa-minus {
                  display: inline-block;
                }
              }
            }
          }
        }
      }

      ul {
        li {
          position: relative;

          a {
            display: inline-block;
            width: 100%;
            font-size: 1.4rem;
          }

        }
      }

      .level {
        &-3 {
          padding-right: 4rem;

          > li {
            padding-bottom: 5.6rem;

            &.active {
              > a {
                color: $colorPrimary;
                text-decoration: none;
              }
            }

            > a {
              color: $colorBlack;
              text-transform: uppercase;
              font-weight: 500;
              position: relative;

              &:after {
                position: absolute;
                content: "";
                width: 5.4rem;
                height: .4rem;
                background: $colorPrimary;
                margin-top: 1rem;
                top: 100%;
                left: 0;
              }
            }
          }
        }

        &-4 {
          transition: all ease .2s;
          max-height: 0;
          overflow: hidden;

          &.active {
            max-height: 90rem;

          }

          > li {
            padding: 3.7rem 2rem 0;

            > a {
              text-align: left;
              margin-top: 0;
              font-weight: 700;
              margin-bottom: .4rem;
            }
          }
        }

        &-5 {
          > li {
            &.active {
              a {
                text-decoration: underline;
              }

            }

            > a {
              text-align: left;
              font-weight: 300;
              line-height: normal;
              margin-bottom: .2rem;
            }
          }
        }
      }

      .category {
        &-title {
          strong {
            color: $colorBlack;
          }

        }
      }

      a {
        color: $colorBlack;
      }
    }

    &.page {
      &-products {
        .sidebar {
          &-main {
            width: calc(25% - 8px);
            margin-right: 10px;
          }
        }
      }
    }

    .page {

      &-wrapper {
        .columns {
          margin-top: 0px;
          @include fontDefault;
          @include contentMedium;
        }

        .page {
          &-mapagesin .products-grid {
            padding-bottom: 2rem;
            border-bottom: 0.1rem solid $colorLightGrey;


            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }

  }
}
.block-title{
  h2{
    font-size: 3.5rem;
    @include robotoSlab;
    font-weight: 500;
    color: $colorBlack;
  }
}
.page-title-wrapper{
  h1{
    padding: 1.5rem 0 3.8rem;
    @include contentMedium;
    text-align: left;
  }
}
.cms-page-view{
  .page-title-wrapper {
    h1 {
      @include contentSmall;
    }

  }
}

table{
  text-align: left;
  td, th{
    padding: .5rem;
  }
}
/*Account Page*/

.pages.wrapper {
  @include contentSmall;
  padding-bottom: 5rem;
  a{
    color: $colorPrimary;
  }
  ol{
    padding-left: 1.5rem;
    list-style: decimal;

  }
  ul{
  padding-left: 1.5rem;
    li{
      list-style: disc;
    }
  }
  h1,h2,h3,h4,h5{
    text-align: left;
  }
}
.cms-home{
  .page-wrapper{
    .main{
      padding-bottom: 0;
    }
  }
}
/*Page*/
.page {
  &-wrapper {
    /*Breadcrumbs*/
    .breadcrumbs {
      font-size: 1.2rem;

      width: 100%;
      padding: 1.4rem 0 0;

      ul {
        @include flex;

        li {
          font-weight: 500;
          margin-right: 0.35rem;
          @include flex;

          &:after {
            content: "/";
          }

          &:last-child {
            &:after {
              content: "";
            }
          }

          > * {
            font-weight: 500;
          }
        }
      }
    }

    .block.block-addresses-list + div {
      display: flex;
    }

    /*Columns*/
    .main {
      margin: 0 auto;
      padding-bottom: 5rem;
      /*Toolbar*/
      .toolbar {
        margin-bottom: 3rem;
        &.customer-addresses-toolbar {
          font-size: 1.4rem;
          font-weight: bold;
          line-height: 2rem;
          text-align: right;
          margin: 0;
        }

        /*Toolbar Sorter*/
        &-sorter {
          @include fontSmaller;
          font-weight: bold;
          line-height: 2rem;
          text-align: right;
          text-decoration: underline;
          @include flexEnd;
          align-items: center;
          z-index: 1;

          .sorter-label {
            @include flex;
            justify-content: flex-end;
          }

          select {
          }

          .sorter-action {
            display: none;
          }

          select {
            width: inherit;
            cursor: pointer;
            border: 0;
            @include fontSmaller;
            font-weight: bold;
          }
        }

        .pages {
          display: none;

          > strong {
            display: none;
          }

          ul {
            @include flex;
            align-items: center;
            justify-content: center;
            @include contentMedium;
            border-bottom: 1px solid rgba($colorBlack, 0.2);
            padding-top: 2rem;
            padding-bottom: 7.7rem;
            margin-bottom: 1.7rem;

            li {
              padding-left: .5rem;

              a, strong {
                width: 3rem;
                height: 3rem;
                border: 1px solid #D1D1D1;
                @include flex;
                justify-content: center;
                align-items: center;

              }

              span.label {
                display: none;
              }

              a {
                text-align: center;

              }

              span {
                font-size: 1.4rem;
                display: inline-block;
                width: 100%;
              }

              strong {
                @include flex;
              }

              &.pages {
                &-item {
                  &-previous, &-next {
                    a {
                      border: 0;
                      width: auto;
                      padding: 0;
                      margin: 0;

                      &:before {
                        @include fontIcon;

                        font-size: 2rem;
                      }

                      span {
                        display: none;
                      }
                    }
                  }

                  &-previous {
                    padding-right: .8rem;
                    a {
                      &:before {
                        content: "\f0d9";
                      }
                    }
                  }

                  &-next {
                    padding-left: .8rem;

                    a {
                      &:before {
                        content: "\f0da";

                      }
                    }
                  }
                }
              }

              &.current {
                strong {
                  color: $colorWhite;
                  text-align: center;
                  border: 1px solid $colorPrimary;
                  font-weight: normal;
                  background: $colorPrimary;
                }
              }
            }
          }
        }

        /* Pages */
        &-products {
          margin: 0;
        }
      }

      .products-grid + .toolbar-products .pages,
      .posts-grid + .toolbar-posts .pages {
        display: block;
        margin-bottom: -3rem;

        + .toolbar-sorter {
          display: none;
        }
      }

      .products-grid + .toolbar-products .toolbar-sorter {
        display: none;
      }

      .category-image {
        @include flexCenter;
        margin-bottom: 6rem;

        img {
          width: 100%;

        }
      }

      .category-description {
        padding-bottom: 3rem;
      }
      /*old & special Price*/
      .price-final_price {
        .percentage {
          color: $colorPrimary;
          @include fontSmaller;
          margin-right: 1.5rem;
        }

        .special-price {
          color: $colorPrimary;
          .price-label {
            display: none;
          }
        }

        .old-price {
          padding-right: 1.6rem;
          @include flexCenter;
          text-decoration: line-through;

          span {
            @include fontSmaller;
          }

          .price-label {
            display: none;
          }
        }
      }
    }

    .filter {
      @include contentMedium;
      text-align: right;
      margin-top: -9rem;

      &-actions {
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 1.5rem;
        border-bottom: 1px solid $colorBorder;

        a {
          display: inline-block;
        }
      }

      &-current {
        display: inline-block;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid $colorBorder;
        padding: 1.5rem;

        &-subtitle {

          display: none;
        }

        ol {
          justify-content: center;
          @include flex;

          li {
            border-right: 1px solid $colorBorder;
            &:last-child {
              border: 0;
              padding-right: 0;
            }

            .filter {
              &-label {
                width: 100%;
                @include robotoSlab;
                font-weight: 700;
                font-size: 1.3rem;
                padding-bottom: .5rem;
              }
              &-value {
                border-radius: .4rem;
                display: inline-block;
                padding: 0rem .8rem;
                border: 1px solid $colorBorder;
                font-weight: 700;
                font-size: 1.6rem;
                line-height: 3.8rem;
              }
            }

            a, span {
              display: inline-block;

              &.remove {
                padding: 0;
                margin: 0;

                span {
                  display: none;
                }

                &:before {
                  content: "\f410";
                  @include fontIcon;
                }
              }
            }
          }
        }
      }

      &-title {
        text-align: right;
        cursor: pointer;
        display: inline-block;
        width: auto;
        padding: 2rem 0;
        font-size: 1rem;

        &:after {
          vertical-align: middle;
          width: 3rem;
          display: inline-block;
          content: url($imageDir + "filters.svg");
          margin-left: 1.8rem;
        }

        i {
          display: none;

          &:before {
            display: none;
          }
        }
      }

      &-content {
        display: none;
        width: 100%;
        border: 1px solid $colorBorder;

        &.active {
          display: inline-block;
        }

        margin-bottom: 5rem;
      }

      &-options {
        @include flex;

        flex-wrap: wrap;

        &-title {
          width: 100%;
          padding: 1.5rem;
          text-align: center;
          padding-bottom: 0;
          @include robotoSlab;
          font-weight: 700;
          font-size: 1.3rem;

        }

        &-content {
          width: 100%;

          padding: 1.5rem;
          text-align: center;
          border-bottom: 1px solid $colorBorder;

          &:last-child {
            border: 0;
          }

          .swatch {
            &-attribute {
              &-options {
                @include flex;
                justify-content: center;
                flex-wrap: wrap;

                a {
                  border-radius: .4rem;
                  display: inline-block;
                  padding: 1rem .8rem;
                  border: 1px solid $colorBorder;
                  line-height: normal;
                  font-weight: 700;
                  font-size: 1.6rem;
                  margin-right: 1rem;
                  margin-bottom: 1rem;

                }
              }

              &.color {
                .swatch-attribute-options {
                  a {
                    padding: 0;
                    width: 3rem;
                    height: 4rem;
                    position: relative;
                    border: 0;
                    border-radius: .4rem;
                    overflow: hidden;

                    div {
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      left: 0;
                      top: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .page-sharing-wrapper {
      position: absolute;
      top: -3rem;
      right: 0;
    }
  }
}
.page-products{
  .page-wrapper {
    .breadcrumbs {
      ul {
        @include contentMedium;
      }
    }
  }
}
.product-grid.three {
  .products.list {
    justify-content: center !important;
  }
}
.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
  padding: 0 !important;
}
.error404 {
  .page-info-main {
    text-align: center;
  }
}
.widget.magento-widget.category-overview {
  a.action.tocategory.secondary {
    border-color: #000;
    color: #000;
    padding-left: 2.1rem;
    padding-right: 2.1rem;
    &:hover {
      box-shadow: 0 0 1px 1px #000;
    }
  }
}
.wishlist-index-index {
  .page-wrapper {
    .columns {
      .main {
        .products.products-grid {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 4.5rem;
          margin-bottom: 2rem;
          margin-left: 0;
          margin-right: 0;
          .products.list {
            margin-bottom: 0.5rem;
          }
        }
        .actions-toolbar > .primary {
          margin-right: 0.6rem;
        }
      }
    }
  }
}
.widget-content {
  margin-top: -5rem;
}
.widget-title {
  position: relative;
  max-width: 134rem;
  margin: 0 auto;
  margin-bottom: 8rem;
  .title-link {
    text-align: right;
    display: block;
    @include fontSmaller;
    font-weight: bold;
    text-decoration: underline;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  h2 {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: -8rem;
  }
}
.column {
  width: 100%;
}

.customer-account-logoutsuccess, .archive {
  .main {
    text-align: center;
  }
}

.privacy-policy {
  .widget.elementor-widget.text-teaser.small.left {
    margin-bottom: 0;
  }
}
.elementor-widget:not(:last-child) {
  margin-bottom: 0px !important;
}
/*Pagination*/
