/*Product Detail*/
.preview-giftcard{
  background-size: 100% !important;
}

.hidden{
  display: none;
}
.catalog-product-view {
  .modal-popup {
    &._show .modal-inner-wrap {

    }
  }
  .main{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include contentMedium;
    .page-wrapper {
      padding-top: 0;
    }
    .webcrossing-widget-product-slider {
      .block {
        &-title {
          h2 {
            text-align: left;
          }
        }
        &-content{
          .items {
            .item {
              &.content {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
    .product{
      &-info{
        &-media {
          max-width: 60rem;
          position: relative;
          width: 100%;
          margin-top: 4rem;
          margin-bottom: 5.1rem;
          .fotorama__img{
            background: $colorLighterGrey;
          }
          .fotorama__stage__frame.fotorama__active.fotorama_vertical_ratio.fotorama__loaded.fotorama__loaded--img {
            img {

            }
          }
          .fotorama__wrap--toggle-arrows{
            background: transparent;
          }
          .fotorama__arr {
            background: transparent;
            width: 4rem;
            &__arr {
              background: transparent;
              @include fontIcon;
              width: 4rem;
              height: 4rem;
              background: $colorTertiary;
              color: $colorPrimary;
              font-size: 2rem;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              &:before{
                display: inline-block;
              }
            }
            &--prev{
              transform: none;
              > div{
                &:before{
                  content: "\f053";
                }
              }
            }
            &__arr{
              &:before{
                content: "\f054";
              }
            }

          }
          .action.skip {
            display: none;
          }
          .fotorama__nav-wrap.fotorama__nav-wrap--horizontal {
            position: absolute;
            bottom: 0.9rem;
          }
          .fotorama__nav--dots .fotorama__nav__frame {
            height: inherit;
            width: inherit;
          }
          .fotorama__dot {
            width: 9px;
            height: 9px;
            top: inherit;
            left: inherit;
            border-radius: 50%;
            margin-left: 1.3rem;
            margin-right: 1.3rem;
          }
          .fotorama__active .fotorama__dot {
            background-color: black;
            border-color: black;
          }
          .fotorama__fullscreen-icon:focus:after, .fotorama__zoom-out:focus:after, .fotorama__zoom-in:focus:after, .fotorama__arr:focus:after, .fotorama__stage__shaft:focus:after, .fotorama__nav__frame--thumb:focus .fotorama__thumb:after, .fotorama__nav__frame--dot:focus .fotorama__dot:after {
            box-shadow: none !important;
          }
          .fotorama__stage:before, .fotorama__nav:before, .fotorama__stage:after, .fotorama__nav:after {
            display: none !important;
          }
          a.action.skip.gallery-next-area, .action-skip-wrapper {
            display: none;
          }
          .giftcard {
            &-template {
              &-setting{
                display: none;
              }
            }
          }
        }/* End -media */
        &-main {
          width: calc(100% - 65rem);
          margin-top: 4rem;

          line-height: 2.7rem;
          padding-bottom: 6rem;
          display: flex;
          flex-direction: column;
          @media only screen and (max-width: #{$mediumBreakpoint}) {
              width: 100%;
              padding-left: 4rem;
          }
          .page-title{
            font-size: 2.5rem;
            padding-bottom: 0;
            line-height: normal;
            text-align: left;
            padding-top: 0;
            width: 100%;
            @media only screen and (max-width: 768px) {
              padding-bottom: 1.5rem;
            }
            span.price-label {
              display: none;
            }
            .swatch-attribute-label {
              font-size: 1rem;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: 0.15rem;
              display: block;
              line-height: 1;
              padding-bottom: 1.3rem;
            }
          }
          .short_description{
            line-height: 2.2rem;
            font-weight: 400;
          }
        }/* End -media */
        &-details{
          @include flexBetween;
          padding-bottom: 2.8rem;
          .shipping-details {
            a {
              text-decoration: underline;
            }
          }
          & > div {
            padding-right: 4.5rem;
          }
          &:last-child {
            @include fontSmaller;
            padding-bottom: 0;
            justify-content: flex-start;
          }
        }/* End -details */
        &-category {
          ul {
            @include flex;
            li {
              a.action.tocategory.secondary {
                margin-right: 2rem;
                border-color: #C8C8C8;
                color: #000;
                &:hover {
                  box-shadow: 0 0 1px 1px #000;
                }
              }
              &:first-child {
                a {

                }
              }
            }
          }
        }/* End -category */
        &-new {
          position: absolute;
          right: 19.5rem;
          top: -2rem;
          @include icon;
          width: 11.5rem;
          height: 11.5rem;
          span {
            display: none;
          }
        }/* End -new */
        &-special-price {
          position: absolute;
          right: 19.5rem;
          top: -2rem;
          @include icon;
          width: 11.5rem;
          height: 11.5rem;
          span {
            display: none;
          }
        }/* End -special-price */
        &-icon {
          a {
            @extend .emptyButton;
            color: transparent;
            border-radius: 0;
            span {
              display: none;
            }
          }
        }/* End -icon */
        &-brand {
          font-family: "garamond-premier-pro",serif;
          font-size: 4.8rem;
          line-height: 5rem;
          text-align: left;
          font-weight: normal;
          strong {
            font-weight: normal;
          }
        }/* End -brand */
        &-spice_type {
          padding-bottom: 2.5rem;
          strong {
            @include fontLarger;
          }
        }/* End -spice_type */
      }/* End -info */
      &-add {
        &tocart-popup{
          display: none;
        }
        &-form {
          padding-right: 4.5rem;

          .swatch-opt {
            display: flex;
            flex-direction: column;

            .swatch-attribute.size {
              order: 1;
              margin-bottom: 2.3rem;
            }

            .swatch-attribute.color {
              order: 0;
            }
          }

          .swatch-attribute {
            &.color {
              padding-bottom: 2rem;
              @media only screen and (max-width: 768px) {
                padding-bottom: 4.5rem;
              }
            }

            &-label {
              margin-bottom: 1.3rem;
              font-weight: 400;
              display: inline-block;
              width: 100%;
              line-height: normal;
            }

            &-options {
              @include flex;
              align-items: flex-start;

              .swatch-option {
                &.image {
                  border-radius: 0;

                  width: auto;
                  height: auto;
                  padding: 0;
                  img {
                    border: 1px solid $colorBorder;
                    position: relative;

                  }

                  .label {
                    width: 100%;
                    display: block;
                    font-style: italic;
                    font-size: 1rem;
                    margin-top: 0.5rem;
                    line-height: normal;
                    border: 1px solid $colorWhite;

                  }

                  &.selected {
                    border: 0;

                    img {
                      border: 2px solid $colorBlack;

                    }
                  }
                }

                &.text {
                  width: auto;

                  border: 0.5px solid $colorBorder;
                  height: 3.5rem;
                  border-radius: 0;
                  @include flexCenter;
                  margin-right: 1.2rem;
                  font-size: 1.4rem;
                  min-width: 3.5rem !important;
                  &.selected {
                    border: 2px solid black;
                  }
                }
              }

            }
          }

          .box-tocart {
            padding-top: 4.5rem;
            padding-bottom: 3rem;

            .fieldset {
              width: 100%;
              @include flex;

              .field.spice_amount {
                width: calc(100% - 32rem);
              }

              .field.qty {
                width: auto;
                @include flexCenter;

                label {
                  letter-spacing: 0.09rem;
                  margin-right: 0.7rem;
                }

                .control {
                  @include flex;
                  align-items: center;

                  input {
                    &#qty {
                      border: 1px solid $colorBorder;
                    }
                  }

                  .remove, .add {
                    cursor: pointer;
                    text-decoration: none;
                    text-align: center;
                    width: 2.2rem;
                    height: 2.2rem;
                    border: 0.1rem solid $colorBlack;
                    border-radius: 50%;
                    font-size: inherit;
                    line-height: 1;
                    font-weight: normal;
                    color: transparent;
                  }

                  .remove {
                    @include background;
                    background-size: 1rem;
                  }

                  .add {
                    @include background;
                    background-size: 1rem;
                  }

                  .input-text.qty {
                    @include fontDefault;
                    width: 4.8rem;
                    text-align: center;
                    text-decoration: none;
                    font-size: 1.8rem;
                    line-height: 2.9rem;
                    border: none;
                    line-height: normal;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    color: $colorBlack;
                  }
                }
              }

              .actions {
                margin-top: 0;
                min-width: 19rem;
                min-height: 0;
                margin-left: 1.5rem;
                @include flexEnd;

                button {
                  margin-right: 0;
                  text-transform: normal;
                  border: 0;
                  background: $colorButton;
                  padding-top: 0;
                  padding-bottom: 0;
                  @include roboto;

                  &:before {
                    content: "\f07a";
                    color: $colorWhite;
                    margin-right: 0.5rem;
                    vertical-align: middle;
                    display: inline-block;
                    @include fontIcon;
                    font-weight: 900;

                  }
                }
              }
            }
          }

          .giftcard {
            &-template {
              &-container {
              }

              &-preview {
                max-width: 8rem;
                display: none;

                > div {
                  max-width: 100%;
                }
              }

              &-setting {
                padding-bottom: 5rem;

                .block-title {
                  margin-bottom: 1.4rem;
                }

                &-select {
                  > div {
                    @include flex;
                  }
                }
              }

              &-preview {

              }
            }

            &-design {
              &-button {
                &-container {
                  margin-right: 1.2rem;
                  max-width: 8rem;

                  i {
                    line-height: normal;
                  }

                  img {
                    border: 2px solid $colorWhite;
                  }

                  span {
                    width: 100%;
                    display: block;
                    font-style: italic;
                    font-size: 1rem;
                    margin-top: 0.5rem;
                    line-height: normal;
                    border: 1px solid #fff;
                  }

                  button {
                    border: 0;
                    background: transparent;
                    @include roboto;
                  }

                  &.active {
                    img {
                      border: 2px solid $colorPrimary;
                    }

                  }
                }
              }
            }
          }
        }

        /* End -add-form */
      }
      &-info {
        &-price {
          margin-top: 2.7rem;
          margin-bottom: 3rem;
          @media only screen and (max-width: 768px) {
            padding-bottom: 3rem;
          }
          .price-label{
            display: none;
          }
          .price-final_price {
            font-size: 2.2rem;
            line-height: normal;

            color: black;
            span{
              .price-label{
                display: none !important;
              }
            }
          }

          .price-box.price-final_price {
            @include fontLarger;
            text-align: left;
            justify-content: flex-start;
            @include flex;

            .percentage {
              margin-right: 2rem;
            }

          }
          .sale-price{
            order: 1;
          }
          .price-details {
            display: none;
          }
        }
        &-detailed {
          width: 100%;
          padding-top: 3rem;
          border-top: 1px solid #F7F7F7;
          .items {
            position: relative;
            .item {
              &.title {
                float: left;
                width: auto;
                padding-right: 3rem;
                a {
                  display: block;
                  position: relative;
                  z-index: 2;
                  min-height: 5.3rem;
                  box-sizing: border-box;
                  cursor: pointer;
                  font-weight: bold;
                  text-align: center;
                  padding-bottom: 1.6rem;
                  text-align: left;
                }
                &.active {
                  a {
                    text-decoration: underline;
                    padding-bottom: 1.8rem;
                    border-left: 0;
                  }
                  &+.content .inner-content {
                    opacity: 1;
                  }
                }
                &:first-child a {
                  padding-left: 0;
                }
                &:last-child a{
                  padding-right: 0;
                }
              }
              &.content {
                float: right;
                margin-left: -100%;
                width: 100%;
                margin-top: 5.3rem;
                &.active {
                  display: block;
                }
                .inner-content {
                  opacity: 0;
                  margin: 0 auto;
                  font-size: 1.5rem;
                  line-height: 2.5rem;
                  &.care_instruction{
                    ul{
                      li{
                        list-style: inside;
                      }
                    }
                  }

                  &.features, &.material{
                    ul{

                      li{
                        width: 100%;
                        @include flex;
                        margin-bottom: 1rem;
                        .image{
                           width: 5rem;
                        }
                        .text{
                          width: 100%;
                          padding-left: 2rem;
                        }
                      }
                    }
                  }
                  &.columns {
                    @include flexBetween;
                    flex-direction: row;
                  }
                  .left{
                    width: 100%;
                    max-width: 23.6rem;
                    font-weight: bold;
                  }
                  .right {
                    width: 100%;
                    max-width: 30.5rem;
                    ul {
                      li {
                        @include flexBetween;
                      }
                    }
                  }
                  @media only screen and (max-width: #{$smallBreakpoint}) {
                    flex-wrap: wrap;
                    .left, .right {
                      max-width: inherit;
                      width: 100%;
                    }
                  }
                  .inner:not(:last-child){
                    padding-bottom: 2.5rem;
                  }
                }
              }
            }
          }
        }
      }
      &.attribute.description {
        font-size: 1.6rem;
        padding-bottom: 3rem;
        font-family: "garamond-premier-pro",serif;
        line-height: 2rem;
        max-width: 54rem;
      }
      &-options{
        &-stock, &-shipping, &-sku, &-country, &-size-chart, &-stock-status{
          font-size: 1.2rem;
        }
        &-size-chart{
          padding-bottom: 2rem;
          text-decoration: underline;
          > div{
            display: none;
          }
          span{
            cursor: pointer;
          }
        }
        &-stock-status{
          span{
            i{
              color: #1A9B37;
            }
          }
        }
      }
    } /* End .product */
  }

}