footer{
    .footer{
        &-content{

        }
        /* end .footer-content */
        &-top{
            background-color: $colorPrimary;
            &-container{
                @include contentMedium;
                color: $colorWhite;
                @include fontLarger;
            }
            &-info{
                ul {
                    padding-top: 2.6rem;
                    padding-bottom: 2.5rem;
                    @include flexBetween;
                    align-items: center;
                    li {
                        @include flexCenter;
                        @include fontMedium;
                        .icon{
                            width: 7rem;
                            height: auto;
                            margin-right: 1.6rem;
                            display: inline-block;
                            &.first{
                                content: url($imageDir + "versandkostenfrei.svg");
                            }
                            &.second{
                                content: url($imageDir + "bezahlung.svg");
                            }
                            &.third{
                                content: url($imageDir + "lieferung.svg");
                            }
                            &.fourth{
                                content: url($imageDir + "persöhnlich.svg");
                            }
                        }
                        .fal {
                            margin-right: 1.4rem;
                            font-weight: 300;
                            font-size: 3.4rem;
                            @media only screen and (#{$mobileBreakpoint}) {
                                font-size: 2rem;
                                margin-right: 1rem;
                            }
                        }
                    }
                    @media only screen and (max-width: 1280px) {
                        flex-wrap: wrap;
                        li {
                            padding-bottom: 1rem;
                            padding-top: 1rem;
                            width: 45%;
                            justify-content: flex-start;
                        }
                    }
                }
            }/* end .footer-top-info */
            &-newsletter{
                background: $colorSecondary;
                padding: 3rem 0;
                .section{
                    &-title{
                        display: none;
                    }
                    &-content{

                        .newsletter{
                            @include flexCenter;
                        }
                        .form{
                            @include flexCenter;
                            .field-recaptcha{

                                margin-left: 2rem;
                            }
                        }
                    }
                }
                .title{
                    color: $colorWhite;
                    font-size: 2.5rem;
                    margin-right: 2rem;
                }
                .content{
                    input{
                        border: 1px solid $colorWhite;
                        @include roboto;
                    }
                    label{
                        display: inline-block;
                    }
                    .label{
                        display: none;
                    }
                    .actions{
                        button{
                            border: 1px solid $colorWhite;
                            background: transparent;
                            height: 4.6rem;
                            &:before{
                                content: "GO!";
                                padding: 1.4rem 2rem;
                                color: $colorWhite;
                                @include roboto;
                                font-weight: bold;

                            }
                            span{
                                display: none;
                            }
                        }
                    }
                }
            }
            &-bottom{
                &-container{

                }
            }
        }/* end .footer-top */
        &-main{
            background-color: $colorGrey;
            &-container{
                @include contentMedium;
                padding-top: 5.3rem;
            }
            &-block:not(.third){

                .section{
                    &-content{
                        ul{
                            @include flexCenter;
                            li{
                                a{
                                    font-size: 2rem;
                                    padding: 0 1.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-bottom{
            background-color: $colorBlack;
            color: $colorWhite;
            padding-top: 1.5rem;
            padding-bottom: 1.3rem;
            &-container{
                @include contentMedium;
                @include flexBetween;
            }
        }
    }/* end .footer */
    .inner {
        &-main {
            &-container {
                &.top{
                    @include flexBetween;
                    padding-bottom: 4.5rem;
                    border-bottom: 1px solid #d0d0d0;
                    margin-bottom: 6.5rem;

                    .section{
                        width: 25%;
                        &.third{
                            width: 25%;
                        }
                        &.top{
                            width: 100%;
                        }
                        &-content {
                            @include footerFont;
                            .newsletter{
                                button{
                                    padding-left: 1rem;
                                    padding-right: 1rem;
                                    font-size: 1.4rem;
                                }
                            }
                            p {
                                margin-bottom: 2.7rem;
                            }
                            i {
                                margin-top: 0.3rem;
                                font-size: 2.4rem;
                            }

                        }
                        &.first {
                            margin-right: 12.5rem;
                            @media only screen and (max-width: 1280px) {
                                margin-right: 0;
                            }
                        }
                        .newsletter {
                            .title {
                                display: none;
                            }
                            .form {
                                position: relative;
                                display: flex;
                                span.label {
                                    display: none;
                                }
                            }
                        }

                        &.third {
                            margin-left: 8rem;
                            ul {
                                li {
                                    list-style: disc;
                                    margin-left: 2rem;
                                }
                            }
                        }
                        &.fourth {

                            ul {
                                max-width: 36.7rem;
                                width: 100%;
                                @include flexWrap;
                                justify-content: space-between;
                                li {
                                    max-width: 15.8rem;
                                    width: 49%;
                                    text-align: center;
                                    margin-bottom: 3rem;
                                }
                            }
                        }
                    }
                } /* End .inner-main-container.top */
                &.bottom {
                    padding-bottom: 5.5rem;
                    @include flex;
                    justify-content: space-between;
                    img {
                        max-height: 2rem;
                        height: 3rem;
                    }
                    .section{
                        width: auto;
                        &-content{
                            ul {
                                @include flex;
                                flex-direction: row;

                                li {
                                    margin-right: 3.5rem;
                                }
                            }
                        }
                        &.first {
                            margin-right: 2.5rem;
                            @media only screen and (max-width: 1280px) {
                                margin-right: 0;
                            }
                        }
                        &.second {
                            .section-title{
                                h4{
                                    text-align: center;
                                    padding-bottom: 1.8rem;
                                }

                            }
                            a{
                                padding: 0;
                            }
                            img{
                                height: 5rem;
                                max-height: 4rem;
                            }
                        }
                        .third {
                            text-align: right;
                            .section-content {
                                ul {
                                    justify-content: flex-end;
                                    li {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }/* End .inner-main-container.bottom */
            }/* End .inner-main-container */
        }
        &-bottom{
            &-container{
                &.left{
                    .section{
                        &-content{
                            ul {
                                @include flex;
                                li {
                                    a {
                                        color: white;
                                        margin-right: 1.5rem;
                                        @include footerFont;
                                    }
                                }
                            }
                        }
                    }
                }/* End .inner-bottom-container.left */
                &.middle{
                    @include fontSmaller;
                }/* End .inner-bottom-container.middle */
                &.right{
                    letter-spacing: 0.09rem;
                    font-size: 1.6rem;
                }/* End .inner-bottom-container.right */
            }/* End .inner-bottom-container */
        }/* End .inner-bottom */
    }
}/* End footer */
