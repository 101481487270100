
/*Modal*/
.checkout-index-index .modal {
  &-inner-wrap {

    max-width: 80rem;
  }
}
body._has-modal {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
#opc-new-shipping-address{
  width: 100%;
  > form{
    padding-right: 2rem;
  }
}
.modal {
  &-slide, &-popup {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    &._show{
      visibility: visible;
      @include flex;
      justify-content: center;
      align-items: center;
      > div{
        display: none;
      }
      .modal{
        &-inner-wrap{
          display: flex;
          top: 0;
          opacity: 1;
        }
      }
    }
    .modal{
      &-inner{
        &-wrap{
          background-color: #fff;
          box-shadow: 0 0 12px 2px rgba(0, 0, 0, .35);
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
  &-slide{
    left: 44px;
    z-index: 900;
    &._inner-scroll{
      .modal{
        &-inner-wrap {
          -ms-flex-direction: column;
          -webkit-flex-direction: column;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          flex-direction: column;
          overflow-y: visible;
          width: 90%;
        }
        &-header, &-footer{
          -webkit-flex-grow: 0;
          -webkit-flex-shrink: 0;
          flex-grow: 0;
          flex-shrink: 0;
        }
        &-header{
          min-height: 2rem;
        }
        &-content {
          overflow-y: auto;
          font-size: 1.4rem;
          line-height: 1.4;
          h3 {
            text-align: left;
            padding-top: 2rem;
          }
        }
        &-footer {
          padding-top: 2rem;
          margin-top: auto;
        }
      }
    }
    .modal{
      &-inner-wrap{

        -webkit-transition-property: -webkit-transform, visibility;
        height: 100%;
        overflow-y: auto;
        position: static;
        transition-duration: .3s;
        transition-property: transform, visibility;
        transition-timing-function: ease-in-out;
        width: 100%;
      }
      &-header, &-content, &-footer{

      }
      &-header{
        margin-bottom: 4rem;
      }
    }
    .action-close {
      padding: 2.1rem 3rem;
    }
    .page-main-actions {
      margin-bottom: -12.9rem;
      margin-top: 2.1rem;
    }
  }

  &-popup{
    left: 0;
    overflow-y: auto;
    z-index: 900;


    &._inner-scroll{
      .modal{
        &-inner-wrap {
          margin: 4rem auto;
        }
        &-content {
          height: auto;
          padding-bottom: 2rem;
        }
      }
    }
    .modal{
      &-content{
        @include flex;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        .product{
          &-info{
            &-price{
              padding: 2rem 0;
              border-top: .1rem solid $colorBorder;
              border-bottom: .1rem solid $colorBorder;
              font-size: 2.2rem;
              color: $colorRed;
              margin-top: 3.1rem;
              .price{
                &:after{
                  content: "";
                }
              }
            }
            &-media{
              width: 40%;
            }
            &-main{
              width: 100%;
              padding-left: 3rem;
              .product{
                &-attribute{
                  font-size: 1.6rem;
                  @include flexVertCenter;
                  margin-bottom: 1rem;
                  &.size{
                    .value{
                      min-width: 3.5rem;
                      border: 0.5px solid $colorBorder;
                      height: 3.5rem;
                      border-radius: 0;
                      @include flexCenter;
                      margin-right: 1.2rem;
                      padding: 5px 5px;
                    }
                  }
                  .label{
                    width: 30%;
                  }
                  .value{
                    margin-right: 1.2rem;

                  }
                }
                h2{
                  padding: 0;
                  @include specialFont;
                  font-size: 2.5rem;
                  margin-bottom: 2.6rem;
                  line-height: 3rem;
                  color: $colorBlack;
                }
                strong{
                  font-weight: 500;
                }
              }
            }
          }

        }

      }
      &-inner-wrap {

        box-sizing: border-box;
        @include flexColumn;
        height: auto;
        @include contentMedium;
        @media only screen and  (min-width: #{$mediumSmallBreakpoint}) {
          max-width: 80rem;
        }

        max-height: 90vh;
        margin: 4rem auto;
        position: relative;
        transition-duration: .2s;
        transition-property: transform, visibility;
        transition-timing-function: ease;
        padding: 4rem 4.5rem;
        border-top: .9rem solid $colorPrimary;
        border-bottom: .9rem solid $colorPrimary;
        transition: all ease .2s;
        top: -100%;
        opacity: 0;
      }
      &-header, &-footer{
        -webkit-flex-grow: 0;
        -webkit-flex-shrink: 0;
        flex-grow: 0;
        flex-shrink: 0;
      }
      &-header{
        margin-bottom: 4rem;
      }
      &-footer {
        @include flex;
        flex-wrap: wrap;
        margin-top: 4rem;
        .action, button{
          width: 49%;
          margin: 0;
          text-align: center;
          border:0;
          font-size: 1.3rem;
          font-weight: 700;
          padding: 1.5rem;
          span{
            display: inline-block;
            width: 100%;
          }
          &.secondary, &-secondary{
            margin-right: 2%;
            background: $colorPrimary;
            color: $colorWhite;
            text-align: center;
          }
          &-view-cart{
            background: $colorWhite;
            color: $colorBlack;
            border: 1px solid $colorBorder;
          }
          &-view-checkout{
            width: 100%;
            background: $colorSecondary;
            color: $colorWhite;
            margin-top: 1.4rem;
          }
        }
        &-actions {
          text-align: right;
        }
      }
      &-title {
        min-height: 1em;
        padding: .5rem 0;
        text-align: left;
        @include specialFont;
        font-size: 2.5rem;
        line-height: 3.5rem;
        position: relative;
        &:after{
          position: absolute;
          content: "";
          left: 0;
          top: 100%;
          height: 4px;
          width: 100%;
          max-width: 8.6rem;
          background: $colorPrimary;
        }
      }
    }
    .action-close {
      padding: 20px;
    }
  }
  &-costum, &-popup, &-slide{
    .modal{
      &-header, &-footer{
        position: relative;

      }
    }
    .action-close{
      -moz-box-sizing: content-box;
      background: none;
      background-image: none;
      border: 0;
      box-shadow: none;
      display: inline-block;
      font-weight: 400;
      line-height: inherit;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 0;
      text-decoration: none;
      text-shadow: none;
      top: 0;
      text-align: center;
      font-size: 1rem;

      &:focus, &:active{
        background: none;
        border: none;
      }
      &:hover{
        background: none;
        border: none;
      }
      &.disabled, &[disabled]{
        cursor: not-allowed;
        opacity: .5;
        pointer-events: none;
      }
      &:before{
        content: "\f00d";
        display: inline-block;
        text-align: center;
        @include fontIcon;
        font-weight: normal;
        font-size: 2rem;
        color: $colorPrimary;
        border-radius: 50px;
        border: 1px solid $colorPrimary;

        height: 2.3rem;
        width: 2.3rem;
        @include flexCenter;
        margin: 0 auto;

      }
      > span{
        color: $colorPrimary;
        width: 100%;
        display: inline-block;
        text-align: center;

      }
      &:active {
        &:before {
          color: inherit;
        }
      }
      &:hover{
        &:before{
          color: #000;
        }
      }
    }
  }
  &-costum{
    .action-close {
      margin: 25px;
    }
  }
  &s-overlay {
    background-color: rgba(51, 51, 51, .55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 899;
  }
} /* Modal */
.fieldset[disabled]{
  .modal{
    &-costum, &-popup, &-slide{
      .action-close{
        cursor: not-allowed;
        opacity: .5;
        pointer-events: none;
      }
    }
  }
}
.webcrossing-popup-1{
  .modal {
    &-inner-wrap {
      padding: 0;
    }
    &-header{
      position: absolute;
      right: 3.7rem;
      top: 3.7rem;
    }
    &-footer{
      display: none;
    }
    &-content{
      .newsletterPopup{
        @include flex;
        .image{
          background: no-repeat center center;
          background-size: cover;
          max-width: 50%;
          width: 100%;
          position: relative;
          line-height: 0;
          .new{
            position: absolute;
            top: 2rem;
            left: 0;
            text-transform: uppercase;
            color: $colorWhite;
            background: $colorPrimary;
            padding: 1.3rem 1.7rem 1.3rem 1.5rem;
            font-style: italic;
            font-weight: bold;
            font-size: 2.1rem;
            line-height: 2.1rem;
          }
          img{
            opacity: 0;
            width: 100%;
            height: 100%;
          }
        }
        .text{
          width: 50%;
          @include flexVertCenter;
          padding: 3.7rem;
          &Container{
            h2{
              text-align: left;
              font-style: normal;
              color: $colorPrimary;
              padding-top: 0;
              font-size: 4rem;
            }
            .note{
              padding-top: 7rem;
              font-weight: 700;
              span{
                font-size: 3rem;
                color: $colorRed;
              }
            }
            .inputfield{
              .title{
                display: none;
              }
              .content{
                .label{
                  display: none;
                }
              }
              form{
                @include flex;
                padding-top: 1rem;
              }
              input{
                width: 100%;
              }
              button{
                width: 10.6rem;
                height: 4.6rem;
                background: $colorPrimary;
                color: $colorWhite;
                border-color: $colorPrimary;
                padding: 0;
                border-radius: 0;
                margin-right: 0;

              }
            }
          }
        }
      }
    }
  }
}
.ie10 .modal-popup._inner-scroll, .ie9 .modal-popup._inner-scroll {
  overflow-y: auto;
}
.ie10 .modal-popup._inner-scroll .modal-inner-wrap, .ie9 .modal-popup._inner-scroll .modal-inner-wrap {
  max-height: none;
}
div#newsletter-error {
  padding: 10px;
}
body._has-modal-custom .modal-custom-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 899;
}
/*Modal Ende*/
