/*Account*/
.customer-account-login, .customer-account-create, .customer-account-forgotpassword, .customer-account-createpassword  {
  .column.main {
    @include contentMedium;
    padding-bottom: 6rem;
  }
  .block-title {
    padding-bottom: 1rem;
      @include fontLarger;
  }
}
.page-layout-2columns-left {
  .page-main {
    padding-top: 0rem;
  }
  .columns {
    max-width: 144rem;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 12rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}
.customer-account-logoutsuccess{
  .columns{
    padding-bottom: 8rem;
  }
}
.account {
  .page {
    &-title {
      &-wrapper {
        padding-top: 5rem;
      }
    }
  }
  .actions-toolbar {
    text-align: left !important;
    margin-top: 1rem;
  }
  .columns {
    margin-bottom: 5rem;
    .column {
      @include contentMedium;
      width: calc(100% - 280px);
      padding-bottom: 5rem;
    }
    .sidebar-main {
      margin-top: 8.5rem;
      .block{
        &-collapsible-nav-title{
          display: none;
        }
      }
      a, strong {
        line-height: 2.6rem;
        display: block;
        @include fontDefault;
      }
      a {
        padding-bottom: 1.1rem;
      }
      .current {
        padding-bottom: 1.1rem;
        strong{
          font-weight: 600;
        }
      }
      @media only screen and (max-width: #{$mediumBreakpoint}) {
        width: 100%;
        padding-bottom: 1.6rem;
        padding-top: 1.6rem;
        .current, a {
          padding-bottom: 0rem;
        }
      }
      .account-nav {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
    p.toolbar-amount {
      display: none;
    }
    .order-products-toolbar.toolbar {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 2rem;
      text-align: right;
      margin: 0;
      select {
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 2rem;
        text-align: right;
      }
    }
    .column.main {
      .table {
        &-wrapper {
          table {
            th {
              text-align: left;
            }
          }
        }
        &-caption {
          display: none;
        }
      }
      span.order-status, .order-date, .actions-toolbar.order-actions-toolbar a {
        @include fontSmaller;
      }
      .table-wrapper.order-items {
        margin-bottom: 1.5rem;
        tr {
          display: flex;
          justify-content: space-between;
          td, th {
            display: block;
            &.name {
              width: 40%;
            }
            &.sku {
              width: 10%;
            }
            &.price {
              width: 15%;
              padding-left: 4rem;
            }
            &.qty {
              width: 30%;
              ul.items-qty {
                @include flex;
                .item {
                  padding-right: 1rem;
                }
              }
            }
            &.subtotal {
              text-align: right;
              width: 15%;
            }
          }
        }
        tfoot {
          margin-top: 2rem;
          display: block;
        }
      }
      .order-details-items.ordered {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

      }
      ul.items.order-links {
        display: none;
      }
      .limiter {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        @include flex;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        select#limiter {
          width: 7.5rem;
          margin-left: 0.5rem;
          margin-right: 1rem;
        }
      }
      .box {
        position: relative;

        &-content {
          padding-bottom: 1.5rem;
        }

        &-actions {
          position: absolute;
          right: 0;
          top: 0;
          @include flexStart;
          justify-content: space-between;
        }
      }
      .block-title, .legend {
        position: relative;
        width: 100%;
        margin-bottom: 2rem;
        @include fontLarger;
        font-weight: bold;
        border-bottom: 0.1rem solid $colorBlack;
        padding-bottom: 1rem;
      }

      form {
        &.form-address-edit {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          fieldset {
            width: 49%;
          }
          @media only screen and (max-width: 768px) {
            justify-content: center;
            fieldset {
              width: 100%;
            }
          }
        }
      }
      .block{
        &-dashboard {
          &-addresses {
            .block-title {
              .action {
                display: none;
              }
            }
          }
          &-orders{
            .block-content {
              > * {
                flex-basis: 100%;
              }
            }

            table {
              width: 100%;
              .table-caption {
                text-align: left;
              }
              th {
                text-align: left;
              }
            }
          }
        }
        &-addresses {
          &-default {
            padding-bottom: 2.5rem;
          }
          &-list{
            .block-title {
              margin-bottom: 0;
            }
            p.empty {
              margin-top: 1rem;
              margin-bottom: 1rem;
              @include fontSmaller;
            }
          }
        }
      }
      @media only screen and (max-width: #{$mediumBreakpoint}) {
        width: 100% !important;
      }
    }
  }
  .wishlist {
    .limiter {
      margin: 0;
    }
    .product{
      &-item{
        &-info{
          @include flex;
          flex-wrap: wrap;
          > div, strong {
            width: 100%;
          }
          .price{
            &-configured_price{
              font-weight: bold;
            }
            &-details {
              @include fontSmaller;
              padding-top: 1rem;
            }
          }
        }
        &-inner{
          padding-top: 1.5rem;
          position: relative;
          .field.comment-box label {
            display: none;
          }
          .box-tocart {
            .field.qty {
              position: absolute;
              top: -3.6rem;
              right: 0;
              display: flex;
              align-items: center;
            }
            .control {
              margin: 0;
              margin-left: 1rem;
              input {
                width: 5rem;
              }
            }
          }
        }
        &-comment{
          width: 100%;
          min-height: 150px;
          padding: 1rem;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
.customer-account-create {
  h1 {
    padding-bottom: 5rem;
  }
  .actions-toolbar {
    display: flex !important;
    width: 100%;
  }
  form#form-validate {
    @include flexWrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    .fieldset {
      width: 49%;
      &.additional_info{
        margin-right: auto;
      }
      legend.legend {
          @include fontLarger;
          font-weight: bold;
          padding-bottom: 1rem;
      }
      @media only screen and (max-width: 1280px) {
        width: 100%;
      }
    }
  }
}

.additional-addresses {
  table {
    position: relative;
    .col{
      &.state, &.phone {
        display: none;
      }
      &.actions {
        padding-right: 0;
      }
    }
  }
}

/*Login*/
.login-container {
  @include flexBetween;
  align-items: flex-start;
  .block {
    width: 49%;
    border: none;
    display: block;
    .primary {
      display: block;
    }
    &-content {
      .actions-toolbar {
        margin-top: 1.5rem;
      }
    }
  }
}
/*Passwort*/
.password.required {

  .control {
    display: flex;
    flex-direction: column;
    #password {
      order: 1;
      &-strength {
        &-meter {
          line-height: 1;
          &-container {
            background-color: $colorError;
            padding: 1rem 0 1rem 1rem;
            order: 2;
            color: $colorWhite;
            margin-bottom: 1rem;
            &.password {
              &-very {
                &-strong {
                  background-color: $colorSuccess;
                }
              }
              &-strong {
                background-color: $colorSuccess;
              }
              &-medium {
                background-color: $colorInfo;
              }
              &-weak {
                background-color: $colorError;
              }
              &-error {
                background-color: $colorError;
              }
            }
          }
        }
      }
    }

    #password-error {
      color: $colorError;
      margin: 0.5rem 0;
      order: 3;
    }

    #password {

    }
  }
}
