
.page-products{

  .category {
    &-image{
      margin-bottom: 0;
      width: 48%;
      img{
        display: block;
      }
    }
    &-text{
      width: 100%;
      padding: 2rem 0;

    }
    &-image + &-text{

    }

    &-information{
      background: $colorTertiary;
    }
    &-view {
      @include contentMedium;
      @include flex;
      align-items: center;
      padding: 0;
      &.hasImage{
        width: 100%;
      }
    }
    &-title{
      @include robotoSlab;
      width: 45%;
      width: 100%;
      font-size: 3rem;
      line-height: 5.3rem;
      font-weight: 500;
      text-align: left;
      margin-bottom: 1.3rem;
      &:after{
        content: "";
        width: 8.6rem;
        height: .4rem;
        background: $colorPrimary;
        display: block;
      }
      h2{
        font-size: 1.87rem;
        line-height: 2.3rem;
        color: $colorBlack;
        padding-top: 0rem;
        padding-bottom: 1.3rem;

        text-align: left;

      }

    }
    &-description{
      width: 55%;
      width: 100%;

      padding-bottom: 0;
    }
  }
  .category-image + .category-text {
    padding-left: 3.5rem;
    width: 52%;
  }
  .main{




    .page-title{
      text-align: left;
      font-size: 3rem;
      margin-bottom: 2.8rem;
      padding: 0;
      line-height: normal;
      &-wrapper{
        @include contentMedium;
      }
    }
    .toolbar {
      padding-bottom: 4.8rem;
    }

  }

}
.sale-price{
  .price{
    color: $colorRed;
  }

}
.products{
  &.list{
    @include flexStart;
    flex-wrap: wrap;

    .product {
      &-item {
        margin-right: 10px;
        padding-bottom: 5rem;
        width: calc(32.99% - 5px);
        &:nth-of-type(3n) {
          margin-right: 0;
        }
        &-image{
          background-color: #f8f6f5;
          position: relative;
          overflow: hidden;
        }
        &-photo{
          max-width: 100%;
          display: inline-block;
          text-align: center;
          width: 100%;
          border: 1px solid $colorImgBorder;
          >span{

          }
          span {
            display: inline-block;
            max-width: 100%;
            background: $colorLighterGrey;
            padding: 0 !important;
            width: 100% !important;

          }
          img {
            width: 100%;
            height: auto;
            vertical-align: top;
          }
        }
        &-new{
          position: absolute;
          left: 0;
          top: 1.4rem;
          background: $colorPrimary;
          color: $colorWhite;
          font-style: italic;
          font-weight: 700;
          padding: .6rem 1.3rem .6rem 1.1rem;
          font-size: 1.4rem;
          line-height: normal;

        }
        &-color{
          transition: all ease .2s;
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
          &.active{
            bottom: 0;
            .swatch {
              &-attribute {
                margin-bottom: 0;
              }
            }
          }
        }
        &-overlay{
          .special_price{
            position: absolute;
            top: 1.8rem;
            left: 0rem;
            width: auto;
            height: auto;
            display: inline-block;
            content: "";
            width: 5.7rem;
            height: 3.3rem;
            background: $colorRed;

            > span {
              font-size: 1.7rem;
              letter-spacing: 0.07rem;
              font-weight: 700;
              text-transform: uppercase;
              color: $colorWhite;
              display: flex;
              vertical-align: middle;
              align-items: center;
              justify-content: center;
              line-height: normal;
              line-height: 1.4rem;
              &.text{
                font-size: 1.2rem;
                font-weight: 700;
                font-style: italic;
                margin-top: .2rem;

              }
            }
          }
          .swatch{
            &-attribute{
              margin-bottom: -35rem;
              transition: all ease .2s;
              input{
                display: none;
              }
            }
            &-option{
              width: 19%;
              margin-right: 1.26%;
              padding: 1rem;
              background: $colorWhite;
              transition: all ease .2s;
              cursor: pointer;
              display: block !important;
              &:nth-of-type(5){
                margin-right: 0;
              }
              &:nth-child(n+6){
                display: none !important;
              }
              img{
                transition: all ease .2s;
                opacity: 0.8;
                height: auto !important;
              }
              &:hover{
                img{
                  opacity: 1;
                }

              }
              span{
                display: none;
              }
              &.selected{
                img{
                  opacity: 1;
                  transform: scale(1.1);
                }

              }
            }
            &-attribute{
              &-options{
                @include flex;

                position: relative;
                background: transparent;
                padding: 1rem 1rem;
                padding-top: 0;

                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e7e3d9+0,e7e3d9+100&0+0,0.3+50,0.7+100 */
                background: -moz-linear-gradient(top,  rgba(231,227,217,0) 0%, rgba(231,227,217,0.3) 50%, rgba(231,227,217,0.7) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  rgba(231,227,217,0) 0%,rgba(231,227,217,0.3) 50%,rgba(231,227,217,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  rgba(231,227,217,0) 0%,rgba(231,227,217,0.3) 50%,rgba(231,227,217,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e7e3d9', endColorstr='#b3e7e3d9',GradientType=0 ); /* IE6-9 */

                > a{
                  width: 100%;
                  display: flex;
                  align-items: flex-end;
                  justify-content: flex-start;
                  font-size: 0;
                  color: rgba($colorWhite, 1);
                  position: absolute;
                  bottom: 100%;
                  right: 0;
                  padding: 0 1rem;
                  text-align: left;
                  padding-bottom: 1rem;
                  padding-top: 3rem;
                  transition: all ease .2s;

                  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#77897b+0,77897b+100&0+0,0.3+50,0.7+100 */
                  background: -moz-linear-gradient(top,  rgba(119,137,123,0) 0%, rgba(119,137,123,0.3) 50%, rgba(119,137,123,0.7) 100%); /* FF3.6-15 */
                  background: -webkit-linear-gradient(top,  rgba(119,137,123,0) 0%,rgba(119,137,123,0.3) 50%,rgba(119,137,123,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
                  background: linear-gradient(to bottom,  rgba(119,137,123,0) 0%,rgba(119,137,123,0.3) 50%,rgba(119,137,123,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0077897b', endColorstr='#b377897b',GradientType=0 ); /* IE6-9 */

                  span{
                    line-height: normal;
                    padding-left: .5rem;
                    &:before{
                      font-size: 1rem;
                      content: "Colours";
                    }

                  }
                  &:before{
                    content: "\f067";
                    font-size: 1.5rem;
                    padding: .2rem .5rem;
                    @include fontIcon;
                    line-height: normal;
                    color: rgba($colorWhite, 1);
                    font-weight: 300;
                    display: inline-block;
                    border: 1px solid rgba($colorWhite, 1);

                  }
                }
              }
            }
          }
        }
        &-details{
          text-align: center;
          font-size: 1.35rem;
          margin-top: 2.1rem;

          a{
            span{
              display: block;
              font-style: italic;
            }
          }
        }
        &-price{

          .price{
            &-box{
              @include flex;
              flex-direction: row-reverse;
              justify-content: center;
              span.price-label {
                display: none;
              }
              span.price {
                font-weight: bold;
                padding-top: 0.5rem;
                display: block;
              }

            }


            &-details {
              display: none;
            }
          }
        }
        &-brand {
          font-family: "garamond-premier-pro",serif;
          font-size: 1.2rem;
          line-height: 1.8rem;
          letter-spacing: 0.016rem;
        }

      }

    }
  }
  &.product-grid{

  }
}
