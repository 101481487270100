.checkout-cart-index {
  .cart-empty {
    padding-left: 5rem;
    padding-right: 5rem;
    text-align: center;
    text-align: center;
    padding-bottom: 5rem;
  }
  h1 {
   @include contentMedium;
    text-align: left;
    line-height: 1;
  }
}
.message.info.empty {
  margin-bottom: 6rem;
}
.checkout-index-index * {
  outline: none;
}
.checkout-index-index {
  .page{
    &-header{
      .header{
        &-main{
          &-logo{
            a{
              padding: 3.9rem 0;
            }
          }
        }
      }
    }
    &-main {
      padding-top: 0;
      @include contentMedium;
    }
    &-title{
      &-wrapper {
        padding-top: 0;
      }
    }
  }
  .step-title {
    margin-bottom: 4.8rem !important;
  }
}
.checkout {
  &-shipping_method {
    td.col.col-method {
      input.radio {
        margin-bottom: 0;
      }
    }
    #checkout-shipping-method-load {
      table.table-checkout-shipping-method {
        width: 100%;
        border-collapse: collapse;
        .col {
          cursor: pointer;
          border-bottom: 0.1rem solid $colorBlack;
          font-size: 1.4rem;
          line-height: 1;
        }
        tr {
          td {
            padding: 2.5rem;
          }

          &:last-child {
            .col {
              border-bottom: 0px;
            }
          }
        }
      }
    }
  }
  &-billing-address {
    margin-bottom: 2rem;
    .billing{
      &-address{
        &-details{
          br{
            display: block;
          }
        }
        &-same-as-shipping-block{
          margin-bottom: 1rem;
          margin-top: 1rem;
          @include flex;
          align-items: center;
          input {
            width: inherit;
            margin-right: 1rem;
            margin-bottom: 0;
          }
        }
        &-details{
          line-height: 1.9;
        }
      }
    }
    .field-select-billing {
      margin-bottom: 2rem;
    }
    fieldset.fieldset {
      padding-bottom: 0;
      .control {
        margin: 0.5rem 0 1rem;
      }
    }
  }
  &-agreement.field.choice.required {
    padding-left: 0px;
    padding-top: 5px;
    @include flex;
    align-items: flex-start;

    input {
      width: inherit;
      margin-right: 1rem;
      margin-left: 0;
    }
    label:after {
      color: $colorWhite;
    }
  }
  &-agreements{
    &-block{
      margin-top: 1.5rem;
      background:$colorPrimary;
      color: $colorWhite;
      padding: 1.5rem 2rem;
      a{
        color: $colorWhite;
      }
    }
  }
  &-container {
    @include flexWrap;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 1.4rem;
    letter-spacing: 0.14rem;
    .opc-progress-bar {
      padding-bottom: 5rem;
      padding-top: 5rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      counter-reset: i;
      max-width: 95.2rem;
      li {
        margin: 0 0 1rem;
        display: inline-block;
        position: relative;
        text-align: center;
        vertical-align: top;
        width: 50%;
        max-width: 46.4rem;
        &:before {
          background: #4B5458;
          top: 2.9rem;
          content: "";
          height: 0.2rem;
          left: 0;
          position: absolute;
          width: 100%;
        }
        &:first-child:before {
          border-radius: 0.6rem 0 0 0.6rem;
        }
        &:last-child:before {
          border-radius: 0 0.6rem 0.6rem 0;
        }
        > span {
          display: block;
          padding-bottom: 4.5rem;
          width: 100%;
          word-wrap: break-word;
          color: $colorBlack;
          text-align: center;
          font-size: 1.4rem;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 1.2rem;
          &:after {
            left: 50%;
            position: absolute;
            margin-left: -1.3rem;
            top: 2rem;
            content: counter(i);
            counter-increment: i;
            background: white;
            padding: 3px;
            border-radius: 50%;
            border: 0.5px solid #4B5458;
            height: 2.3rem;
            width: 2.3rem;
            font-size: 1.4rem;
            padding-left: 5px;
            font-weight: bold;
            color: black;
            background-size: 70%;
            content: counter(i);
            counter-increment: i;
            @include flex;
            align-items: center;
            justify-content: center;
          }
        }
        &._active {
          &:before {
            background: #4B5458;
            height: 0.2rem;
          }

          > span {
            color: $colorBlack;

            &:after {
              border: 2px solid #4B5458;
            }
          }
        }
        &._complete {
          cursor: pointer;
          &:before {
            background: $colorTertiary;
          }
          > span {
            color: $colorTertiary;
            &:after {
              border-color: $colorTertiary;
              color: $colorTertiary;
            }
          }
        }
      }
    }
    .authentication {
      &-wrapper {
        .action-auth-toggle {
          background: none;
          padding: 0;
          border: none;
          color: $colorPrimary;
          @include fontLarger;
          &:hover {
            opacity: 0.5;
          }
        }
      }
      &-dropdown {
        position: absolute;
        background: $colorWhite;
        border: 1px solid $colorGrey;
        margin-top: 0.5rem;
        max-width: 1500px;
        width: 100%;
        z-index: 101;
        box-shadow: 0 0.3rem 0.3rem $colorGrey;
        padding: 2.5rem 2rem;
        visibility: hidden;
        &._show {
          z-index: 100;
          transform: scale(1, 1);
          transition: transform linear 0.1s, visibility 0s linear 0s;
          visibility: visible;
        }
        .modal {
          &-inner {
            &-wrap {
              display: flex;
              flex-direction: column;

            }
          }
          &-header {
            button {
              display: inline-block;
              background-image: none;
              background: none;
              border: 0;
              box-shadow: none;
              line-height: inherit;
              margin: 0;
              padding: 0;
              text-decoration: none;
              text-shadow: none;
              font-weight: 400;
              height: 4rem;
              position: absolute;
              right: 0;
              top: 0;
              width: 4rem;
              > span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 0.1rem;
                margin: -0.1rem;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 0.1rem;
              }
              &:before {
              }
              &.disabled {
                pointer-events: none;
                opacity: 0.5;
              }
            }
          }
          &-content {
            text-align: left;
            .block-title {
              margin: 1rem 0;
            }
            .control {
              margin: 0rem 0;
              display: flex;
              flex-wrap: wrap;
              input {
                width: 100%;

              }
            }
            .actions-toolbar {
              padding-top: 1rem;
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
    }
    .opc {
      &-estimated {
        &-wrapper {
          display: none;
        }
      }
      &-wrapper {
        width: 100%;
        max-width: calc(100% - 48.8rem);
        .fieldset {
          .g-recaptcha{
            margin-bottom: 2rem;
          }
          div.field {
            margin-bottom: 2rem;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
        label.label {
          border: none;
          line-height: 1;
          display: block;
          font-size: 1.4rem;
          letter-spacing: 0.14rem;
          margin-bottom: .5rem;
        }
        ol#checkoutSteps {
          list-style: none;
          .shipping-address-item {
            margin-bottom: 1.5rem;
            margin-right: 2%;
            width: 32%;
            padding: 2rem;
            border: .1rem solid $colorTertiary;
            &:nth-of-type(3n){
              margin-right: 0;
            }
            .action{
              margin-top: 1rem;
              padding: 1.5rem;
              width: 100%;
              background: $colorTertiary;
              border-color:$colorTertiary;
            }
            &.selected-item{
              border-color: $colorBlack;
              color: $colorBlack;
              .action{
                background: $colorPrimary;
                color: $colorWhite;
                border-color: $colorPrimary;
                min-width: 0;
              }
            }
            &s{
              width: 100%;
              @include flexWrap;
            }
          }
        }
        .new-address-popup {
          margin-bottom: 2.5rem;
        }
        .control._with-tooltip {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          position: relative;
          .input-text {
            flex-basis: 94%;
            color: $colorBlack;
          }
          .field-tooltip {
            cursor: pointer;
            @include flex;
            align-items: center;
            &.toggle {
              #tooltip-label {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
              }
              #tooltip {
                &:before {
                  @include fontIcon;
                  font-size: 2rem;
                  color: $colorBlack;
                  content: "#{$iconQuestion}";
                  font-weight: 400;
                }
              }
            }

            &._active .field-tooltip-content {
              display: block;
            }

            .field-tooltip-content {
              background: $colorLighterGrey;
              border: 0.1rem solid $colorLighterGrey;
              border-radius: 0.1rem;
              font-size: 1.4rem;
              padding: 1.2rem;
              width: 27rem;
              display: none;
              margin-left: 0;
              margin-right: 2rem;
              position: absolute;
              text-transform: none;
              top: -0.9rem;
              word-wrap: break-word;
              z-index: 2;

              &:before, &:after {
                border: 1rem solid transparent;
                height: 0;
                width: 0;
                border-left-color: $colorLighterGrey;
                right: -2.1rem;
                top: 1.2rem;
                content: "";
                display: block;
                position: absolute;
                z-index: 3;
              }

              &:before {
                border-left-color: $colorGrey;
              }

              &:after {
                border-left-color: $colorLighterGrey;
                width: 0.1rem;
                z-index: 4;
              }
            }
          }

          .note {
            flex-basis: 100%;
            margin-top: 0.5rem;
            color: $colorBlack;
          }
        }

        .control {
          margin: 0rem 0;

          input {
            width: 100%;
            color: $colorBlack;
            letter-spacing: 0.8px;
            margin-bottom: 0;
          }
        }

        .step-title {
          @extend h3;
          font-size: 2.2rem;
          line-height: 4.3rem;
          letter-spacing: 0.33rem;
          text-transform: uppercase;
          font-weight: bold;
          border: 0;

          text-align: left;
        }



        fieldset.field.street.admin__control-fields.required {
          margin-top: 0;

          legend {
            display: none;
          }
        }

        form fieldset {
          padding-bottom: 0;
          &.street{
            padding-bottom: 1.5rem;
          }
        }

        li#shipping {
          .amazon-button-container {
            margin-bottom: 2rem;
          }

          form.form-login {
          }

          .note {
            border: none;
            line-height: normal;
            display: block;
            font-size: 1.4rem;
            letter-spacing: 0.14rem;
            margin-bottom: 2rem;
          }
        }



        #payment {
          legend.legend {
            @extend h3;
            width: 100%;
            margin-bottom: 2rem;
            padding-bottom: 1rem;
            text-align: left;
            border-bottom: 0.1rem solid black;
          }

          .field.choice{
            margin-bottom: 0;
          }
          .payment {
            &-method {
              margin-bottom: 0 !important;
              cursor: pointer;
              border-bottom: 0.1rem solid $colorBlack;
              padding: 1.5rem 0;

              &-content {
                display: none;

              }

              .messages {
                margin: 0;
              }

              &._active {
                .payment-method-content {
                  display: block;
                  padding-top: 1.5rem;
                  padding-left: 2rem;
                  padding-right: 2rem;

                  .mage-error {
                    margin-left: 15px;
                    font-size: smaller;
                  }
                }
              }
              &-title {
                font-weight: bold;
                width: 100%;
                @include flex;
                align-items: center;
                padding-left: 0;
                label {
                  cursor: pointer;
                  padding-left: 1rem;
                  padding-bottom: 0;
                }

                input {
                  width: 1.5rem;
                  margin-bottom: 0;
                }
                label {
                  cursor: pointer;
                }
              }

              label button {
                text-transform: none;
                background-color: transparent;
                color: $colorWhite;
                font-weight: normal;
                padding: 0;
                vertical-align: inherit;
                border: none;
                margin-right: 0;
                text-align: left;
                position: relative;
                &:hover {
                  text-decoration: underline;
                }
              }

              .primary {
                width: 100%;
                margin-top: 1rem;
              }

              button.action.primary.checkout {
                width: 100%;
                margin-top: 10px;
              }





              &:last-child {
                border-bottom: 0;
              }
            }
          }

          .payment-group {
            .step-title {
              margin-bottom: 1rem !important;
            }
          }
        }

        button.action.action-edit-address {
          margin-top: 1.5rem;
        }
      }
      &-block-summary {
        span.title {
          padding-bottom: 0;
          display: block;
          text-align: left;
          text-transform: uppercase;
          line-height: 1;
          font-size: 2.2rem;
          letter-spacing: 0.33rem;
          padding-bottom: 5.3rem;
          font-weight: bold;
        }
        table {
          width: 100%;
          margin-top: 1rem;
          margin-bottom: 1rem;
          border-bottom: 0.1rem solid rgba($colorBlack, 0.5);
          caption.table-caption {
            display: none;
          }
          th, td {
            text-align: left;
            line-height: 1;
            padding: 0;
            padding-bottom: 0px;
            font-size: 1.6rem;
            letter-spacing: 0.16rem;
            padding-bottom: 1.6rem;
          }
          td {
            text-align: right;
          }
          tr.grand.totals {
            padding-top: 0.5rem;
          }
        }

        .items-in-cart {
          margin-top: 1rem;
          cursor: pointer;

          .title {
            margin-bottom: 2rem;
            position: relative;
            padding: 1rem;
            background: $colorTertiary;

            &:after {
              color: $colorGrey;
              @include icon;
              width: 1.7rem;
              height: 1.7rem;
              float: right;
              position: absolute;
              right: 0;
              bottom: 2rem;
              @include defaultTrans;
            }
          }
          &.active .title {
            &:after {
              transform: rotate(90deg);
              @include defaultTrans;
            }
          }

          .minicart-items {
            list-style: none;


              .product {
                display: flex;
                &-item {
                  padding-bottom: 2rem;
                  margin-bottom: 2rem;
                  border-bottom: 0.1rem solid black;
                  &:last-child {
                    border: none;
                  }
                  &-details {
                    margin-left: 2rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    @include fontDefault;
                    line-height: 2rem;
                  }
                  &-inner {
                    height: 100%;
                    .subtotal{
                      .cart-price{
                        @include fontMedium;
                      }
                    }
                  }
                  &-name-block {
                    padding-bottom: 1rem;
                    @include fontMedium;
                  }
                }
                &.options{
                  @include fontMedium;
                }
              }
          }
        }
      }
      &-summary-wrapper {
        width: 100%;
        padding: 0;
        padding-top: 0;
        max-width: 35rem;
        margin-top: -11rem;

        .modal-inner-wrap {
          font-size: 1.4rem;

          header {
            display: none;
          }



          .opc-block-shipping-information {
            .shipping-information-title {
              border-bottom: 1px solid rgba(0, 0, 0, 0.4);
              margin-bottom: 2rem;
              padding-bottom: 1rem;
              @include flexBetween;

              button {
                background-color: transparent;
              }
            }

            .ship-to {
              margin-bottom: 1.5rem;
            }
          }
        }
      }
    }
    .messages {
      grid-column: 1 / 3;
      grid-row: 3;
    }

    #checkout-loader {
      bottom: 0;
      left: 0;
      margin: auto;
      position: fixed !important;
      right: 0rem;
      top: 0;
      z-index: 9999;
      width: 100%;
      height: 100%;
      background: rgba($colorGrey, 0.75);

      .loader {
        img {
          max-width: 4.4rem;
          position: fixed;
          top: 25%;
          left: 50%;
          z-index: 9999;
        }
      }
    }


    div#registration {
      .action {
        display: block;
        max-width: 20rem;
        margin-top: 1.5rem;
      }
    }

    li#opc-shipping_method {
      margin-top: 2.5rem;
    }

    div#checkout-shipping-method-load, div#checkout-payment-method-load {
      padding: 2rem;
      background-color: $colorTertiary;
      margin-bottom: 2.5rem;
    }

    div#shipping-method-buttons-container, div#checkout-payment-method-container {

      button.primary {
        margin-bottom: 7.1rem;
      }
    }

    div#checkout-shipping-method-load {
      td.col.col-method:first-child {
        width: 1.5rem;
      }

      td.col.col-method:last-child {
        padding-left: 1rem !important;
      }
    }

  }
}



/*Loaden*/


.load.indicator {
  background-color: rgba($colorGrey, 0.5);
  z-index: 9999;

  &:before {
    border-radius: 0.5rem;
    box-sizing: border-box;
    content: "";
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  > span {
    display: none;
  }
}
html {
  position: relative ;
}
.loading-mask {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed !important;
  right: 0rem;
  top: 0;
  z-index: 9999;
  background: rgba($colorGrey, 0.75);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(50% - 22px);
  top: calc(50% - 22px);
  p  {
    display: none;
  }
  > img {
    max-width: 4.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
  }
}
.checkout-success .actions-toolbar {
  margin-top: 15px;
}
form.form.form-login {
  margin-bottom: 2rem;
}
.checkout-onepage-success{
  .page-main{
    .columns{
      .column{
        @include contentMedium;
        #registration{
          padding-bottom: 5rem;
          a{
            margin-top: 15px;
            display: inline-block;
          }
        }
        .actions-toolbar{
          padding-bottom: 5rem;
          a{
            display: inline-block;
          }
        }
      }
    }
  }
}
.catalog-product-view{
  .modal {
    &-popup {
      &._show {
        .modal {
          &-inner-wrap {
            max-width: 80rem;
          }
        }
      }
    }
    &-header{
      position: relative;
    }

  }
}
#block-gift-card-discount {
  display: none;
}
/*Ende*/



