.page-header {
    .nav-toggle {
        display: block;
    }
    .header {
        &-content {
            width: 100%;
            margin: 0 auto;
            position: relative;
            z-index: 3;
            background: $colorWhite;
        }
        /* END .header-content */
        &-top {
            background-color: black;
            color: white;
            &-container {
                @include contentMedium;
                display: grid;
                .inner-top-container {
                    &.left {
                        @include fontMedium;
                        text-transform: uppercase;
                        @include letterSpacing;
                        width: 100%;
                        max-width: 34rem;
                        @include flex;
                        align-items: center;
                    }
                    &.right {
                        @include flex;
                        align-items: center;
                        padding-top: 1.5rem;
                        padding-bottom: 1.5rem;
                        justify-self: flex-end;
                    }
                }
            }

            /* END .header-top-container */
            &-account {
                width: max-content;
                a {
                    color: $colorWhite;
                    text-transform: uppercase;
                    font-weight: bold;
                    @include headerNav;
                }
            }
            &-navigation{
                &.mobile{
                    display: none;
                }
            }
            &-info {
                @include flex;
                align-items: center;
                @include headerNav;
                width: 100%;
                p{
                    width: 100%;
                }
                img{
                    max-width: 6.3rem;
                    height: auto;
                    margin-left: 1rem;
                    display: inline-block;
                    vertical-align: middle;
                    &.maloja{
                        max-width: 11.45rem;
                        margin: 0;
                        margin-top: -0.5rem;
                    }
                }
                .mobile{
                    display: none;
                }
            }
            /* END .header-top-account */
            &-contact {
                padding-right: 2.3rem;
                border-right: 1px solid #fff;
                margin-right: 2.3rem;
                @include headerNav;
                ul {
                    @include flex;
                    align-items: center;
                    li {
                        a {
                            color: white;
                            @include fontMedium;
                            @include flexCenter;
                            @include headerNav;
                            span {
                                margin-left: 0.7rem;
                                display: block;
                                @media only screen and (max-width: #{$mediumBreakpoint}) {
                                    display: none;
                                }
                            }
                            i {
                                font-size: 2rem;
                                margin-left: 0.5rem;

                                &.fab.fa-facebook-square {
                                    margin-left: 4.3rem;
                                    font-size: 2rem;
                                }

                                &.fab.fa-instagram {
                                    font-size: 2rem;
                                }
                                &.fab.fa-whatsapp {
                                    margin-left: 1.5rem;
                                }

                                &.fal {
                                    &.fa-envelope {
                                        font-weight: 300;
                                        margin-left: 4rem;
                                        @media only screen and (max-width: #{$mediumBreakpoint}) {
                                            margin-left: 2rem;
                                        }
                                    }
                                }
                                &.fal.fa-phone-alt {
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                }
            } /* END .header-top-contact */
        }
        /* END .header-top */
        &-main {
            @include defaultFastTrans;
            z-index: 1;
            @include contentMedium;
            border-bottom: 1px solid #F7F7F7;
            &-container {
                @include flexBetween;

                .inner-main-container {
                    background: $colorWhite;
                    &.right {
                        @include flex;
                        align-items: center;

                        .header-top-account {
                            a {
                                color: $colorWhite;
                            }
                        }
                    }
                    &.left {
                        @include flex;
                        align-items: center;
                        width: 100%;


                    }
                }
            }

            /* END .header-main-container */
            &-logo {
                a {
                    display: inherit;
                    height: auto;

                    img {
                        max-height: 6rem;

                        line-height: normal;
                        display: block;
                        @media only screen and (max-width: #{$mediumBreakpoint}) {
                            width: inherit;
                            height: 5rem;
                        }
                    }
                }
            }

            /* END .header-main-logo */
            &-navigation {
                &.desktop {
                    padding-left: 5rem;
                    width: 100%;
                    @include headerNav;
                    nav{
                        > ul {
                            @include flex;
                            align-items: center;
                            > li {
                                > a {
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    padding: 5rem 0;
                                    display: inline-block;
                                    padding-right: 3rem;
                                    font-size: 1.5rem;
                                }

                                > .container {
                                    display: none;
                                    position: absolute;
                                    top: calc(100% - 1px);
                                    left: 0;
                                    width: 100%;
                                    z-index: 4;
                                    padding-top: 4.7rem;
                                    padding-bottom: 4.7rem;
                                    min-width: 32rem;
                                    background: $colorWhite;
                                    -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.42);
                                    -moz-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.42);
                                    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.42);
                                }

                                ul {
                                    @include contentMedium;
                                    @include flex;
                                    padding-left: 20rem;

                                    @media only screen and (max-width: #{$maxBreakpoint}) {
                                        padding-left: 20rem;

                                    }
                                    @media only screen and (max-width: #{$mediumBreakpoint}) {
                                        max-width: $smallContent;
                                    }
                                    @media only screen and (max-width: 768px) {
                                        max-width: 90%;
                                    }
                                    > li {
                                        background: $colorWhite;
                                        width: auto;
                                        position: relative;
                                        transition: all .2s ease;
                                        padding-right: 0;
                                        padding-right: 3rem;
                                        a {
                                            width: 100%;
                                            padding: 0;
                                            font-size: 1.5rem;
                                            position: relative;
                                            text-transform: none;


                                            span {
                                                width: 100%;
                                                display: inline-block;
                                            }
                                        }

                                        &.level1 {
                                            > a {
                                                &:after {
                                                    margin-top: .9rem;
                                                    margin-bottom: .9rem;
                                                    content: "";
                                                    width: 5.4rem;
                                                    height: .4rem;
                                                    background: $colorPrimary;
                                                    display: block;
                                                }
                                            }
                                        }


                                        .container {
                                            left: 0;
                                            top: 0;
                                            padding-top: 0;
                                            padding-left: 0;
                                            display: block;
                                            position: initial;

                                            ul {
                                                display: block;
                                                padding-left: 0;
                                            }

                                            li {
                                                padding: 0;
                                                margin: 0;
                                                a {
                                                    font-size: 1.4rem;
                                                    background: transparent;
                                                    font-weight: 300;
                                                    line-height: 2.4rem;
                                                    padding: 0;
                                                    border: 0;

                                                    &:hover {
                                                        color: $colorPrimary;
                                                    }
                                                }

                                                &:first-child {
                                                    margin-top: 0;
                                                }
                                            }
                                        }
                                        .level2.container{
                                            display: none;
                                        }
                                        &:hover {
                                            > ul {
                                                display: block;
                                            }
                                        }

                                        &.parent {
                                            > a {
                                                &:after {

                                                }
                                            }
                                        }
                                    }
                                }

                                &:hover {
                                    > .container {
                                        display: block;
                                    }
                                    > a{
                                        color: $colorPrimary;
                                    }
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: #{$mediumBreakpoint}) {
                        display: none;
                    }
                }/* END .header-main-navigation.desktop */
            }

            /* END .header-main-navigation */
            &-search {
                .block {
                    &-search {
                        @include flex;
                        align-items: center;
                        &.active {
                            .block {
                                &-title {
                                    &:before {
                                        content: "#{$iconArrow}";
                                    }
                                }
                                &-content {
                                    width: 100%;
                                    left: 0;
                                    top: 17rem;
                                    background: $colorLighterGrey;
                                    z-index: 0;
                                    opacity: 1;
                                    .form{
                                        margin-top: 0.1rem;
                                        border-top: 4px solid $colorLighterGrey;
                                        border-bottom: 4px solid $colorLighterGrey;
                                    }
                                    input{

                                        background: $colorWhite;
                                        @include roboto;
                                        text-transform: none;
                                    }

                                    .actions{
                                        background: $colorPrimary;

                                        display: flex;;
                                        align-items: center;
                                        justify-content: center;


                                        button{
                                            height: auto;
                                            width: auto;
                                            margin-right: 0;
                                            padding: 0 2rem;
                                            background: transparent;
                                            font-size: 0;
                                            &:before{
                                                @include fontIcon;
                                                font-size: 3rem;
                                                font-weight: 500;
                                                color: $colorWhite;
                                                content: "\f002";
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                    &-title {
                        cursor: pointer;
                        padding-right: 1rem;
                        padding-bottom: 0;
                        @include flex;
                        justify-content: center;
                        align-items: center;
                        @media only screen and (max-width: #{$mediumBreakpoint}) {
                            padding-right: 0;
                        }
                        &:before {
                            color: $colorBlack;
                            content: "#{$iconSearch}";
                            @include fontIcon;
                            font-weight: 400;
                            top: 0;
                            font-size: 2.8rem;
                            @media only screen and (max-width: #{$mediumBreakpoint}) {
                                font-size: 1.8rem;
                            }
                        }

                        strong {
                            display: none;
                        }
                    }

                    &-content {
                        position: absolute;
                        top: 10.9rem;
                        width: 100%;
                        overflow: hidden;
                        @include defaultTrans;
                        width: 100%;
                        left: 0;
                        height: auto;
                        z-index: -1;




                        form#search_mini_form {
                            display: flex;
                            @include contentMedium;
                        }

                        .field.search {
                            width: 100%;
                        }

                        button {
                            width: 0;
                            padding: 0;
                            border: none;
                            overflow: hidden;
                        }

                        input {
                            margin-bottom: 0;
                            border: none;
                            font-size: 1.8rem;
                            line-height: 3rem;

                            color: $colorBlack;

                            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                font-size: 1.8rem;


                                line-height: 3.8rem;

                                color: $colorBlack;
                            }

                            &::-moz-placeholder { /* Firefox 19+ */
                                font-size: 1.8rem;


                                line-height: 3.8rem;

                                color: $colorBlack;
                            }

                            &:-ms-input-placeholder { /* IE 10+ */
                                font-size: 1.8rem;


                                line-height: 3.8rem;

                                color: $colorBlack;
                            }

                            &:-moz-placeholder { /* Firefox 18- */
                                font-size: 1.8rem;


                                line-height: 3.8rem;
                                color: $colorBlack;
                            }
                        }

                        label {
                            display: none;
                        }
                    }
                }
            }

            /* END .header-main-search */
            &-cart {
                padding-left: 1.5rem;

                a {
                    @include flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0;
                    border: 0;
                    margin-right: 0;
                    position: relative;

                    &:before {
                        color: $colorBlack;
                        content: "#{$iconCart}";
                        @include fontIcon;
                        position: static;
                        top: 0;
                        font-weight: 900;
                        font-size: 3rem;
                        @media only screen and (max-width: #{$mediumBreakpoint}) {
                            font-size: 1.8rem;
                        }
                    }

                    span.counter-number {
                        background: $colorPrimary;
                        display: inline-block;
                        color: $colorWhite;
                        width: 23px;
                        height: 23px;
                        font-size: 1.4rem;
                        line-height: 20px;
                        text-align: center;
                        position: absolute;
                        top: -10px;
                        right: -15px;
                        border-radius: 50px;
                        font-weight: 800;
                    }

                    span.text {
                        display: none;
                    }

                    span.counter-label {
                        display: none;
                    }

                    span.counter.empty {
                        display: none;
                    }
                }
            }

            /* END .header-main-cart */
            &.scroll {
                top: 0;
                @include defaultFastTrans;

                & + .header-bottom {
                    @include defaultTrans;
                }
            }

            & + .header-bottom.scroll, & + .header-bottom.scrollDown {
                @include defaultTrans;
            }
        }
        /* END .header-main */
    }
}
