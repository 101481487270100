body.cms-no-route {
  .webcrossing-widget-image-slider {
    .block {
      &-content {
        ul {
          li {
            .info {
              color: $colorWhite;
              h2 {
                font-size: 4rem;
                color: $colorWhite;
                font-weight: 700;
                line-height: 4.8rem;
                margin-bottom: 1rem;
              }
              > p{
                font-size: 3rem;
                line-height: 3.8rem;
              }
              .actions{
                a{
                  padding: 1rem 3rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
